import React from "react"
import ImageMeta from "../../components/ImageMeta"
import NuvoImage from "../../components/NuvoImage"
import Button from "@bit/azheng.joshua-tree.button"

import ABOMS from "../../images/logo-ABOMS.svg"
import RDCD from "../../images/badge-rdcd.png"
import SocialReviews from "../../components/SocialReviews/SocialReviews"
import Stars from "../../components/SocialReviews/Stars"

function TopSection(props) {
  let docName = props.post.heading

  if (props.post.hasImages) {
    return (
      <div className="columns is-desktop top-section doctor">
        <Button
          buttonText={props.language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={
            props.language === "es" ? "/es/acerca-de-nosotros/" : props.backUrl
          }
        />

        <div className="column is-2"></div>
        <div className="column is-8 doctor-image-wrapper">
          <div className="wwos-dr-image-wrap">
            <NuvoImage
              useAR
              cloudName="nuvolum"
              publicId={props.post.cutoutImage}
              width="auto"
            ></NuvoImage>
            {/* {props.post.badge && props.post.badge.hasBadge && (
              <img className="badge" src={RDCD} />
            )} */}
          </div>
        </div>
        <div className="column is-1"></div>

        <div className="column doctor-top-intro">
          <div className="doctor-top-intro-inner">
            <h1 className="has-text-centered-touch">{docName}</h1>
            <p className="large-doctor-text has-text-centered-touch large">
              {props.post.topBlurb}
            </p>
            <div className="rating-review">
              {props.post.badge && props.post.badge.hasBadge && (
                <img className="badge" src={RDCD} />
              )}
              <SocialReviews google isCentered language={props.language} />
            </div>
          </div>
        </div>
        <div className="column is-2"></div>
      </div>
    )
  }
  return (
    <>
      <div
        className="columns has-text-centered top-section doctor is-vcentered"
        style={{ backgroundColor: "#fff" }}
      >
        <Button
          buttonText={props.language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={
            props.language === "es" ? "/es/acerca-de-nosotros/" : props.backUrl
          }
        />

        <div className="column is-5"></div>
        <div className="column">
          <h1>{props.post.heading}</h1>
          <p className="large-doctor-text large">{props.post.topBlurb}</p>
        </div>
        <div className="column is-5"></div>
      </div>
      {/* <div className="columns" style={{ marginTop: "44px" }}>
        <div className="column is-offset-2 is-20">
          <div className="hl"></div>
        </div>
      </div> */}
    </>
  )
}

export default TopSection
